<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/radio/drlaura_hero_desktop.png')"
      :mobileImage="require('@/assets/images/radio/drlaura_hero_mobile.png')"
      :mobileHeight="'100%'"
      :height="'550px'"
    >
      <b-container>
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="6"
            offset="6"
            md="5"
            offset-md="0"
            lg="5"
            offset-lg="1"
            offset-xl="2"
            xl="4"
            align-self="end"
          >
            <blockquote class="text-white">
              "If raising your credit score an average of
              <strong>61 points in twenty days or less</strong> sounds smart…
              you've come to the right place."<br /><br />
              <p>
                <strong>Dr. Laura Schlessinger</strong><br />
                <small>SiriusXM Triumph Channel 111</small>
              </p>
            </blockquote>
          </b-col>
          <b-col cols="12" md="7" lg="6" xl="5">
            <b-row>
              <b-col class="hero-text p-5">
                <h1 class="h1 font-weight-bolder mb-4">
                  Where You Get Your Best Credit Score<sup>&trade;</sup>
                </h1>
                <p class="h4 mb-4">
                  Use ScoreMaster<sup>&reg;</sup> to add more points to your
                  credit score and manage your score as you spend.
                </p>
                <b-button
                  to="/"
                  pill
                  size="lg"
                  variant="secondary"
                  class="w-100 text-center"
                >
                  See My Benefits
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <NumbersBluebar>
      <b-row align-h="center">
        <b-col cols="12">
          <p class="text-white text-center mt-2">
            <small
              >* Based upon an average of actual users of
              Scoremaster<sup>&reg;</sup>. Your results may vary and are not
              guaranteed.</small
            >
          </p>
        </b-col>
      </b-row>
    </NumbersBluebar>
  </div>
</template>

<script>
import NumbersBluebar from '@/components/common/NumbersBluebar'

export default {
  name: 'DrLauraLanding',
  components: { NumbersBluebar },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.scoremaster.com/drlaura/',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
