<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/radio/blaze_hero_desktop.png')"
      :mobileImage="require('@/assets/images/radio/blaze_hero_mobile.png')"
      :mobileHeight="'100%'"
      :height="'740px'"
    >
      <b-container>
        <b-row align-h="end" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6" offset-lg="1" xl="5">
            <b-row>
              <b-col
                cols="12"
                order="2"
                order-sm="2"
                order-md="1"
                class="hero-text p-5"
              >
                <h1 class="h1 font-weight-bolder mb-4">
                  Where You Get Your Best Credit Score<sup>&trade;</sup>
                </h1>
                <p class="h4 mb-4">
                  Use ScoreMaster<sup>&reg;</sup> to add more points to your
                  credit score and manage your score points as you spend.
                </p>
                <b-button
                  to="/"
                  pill
                  size="lg"
                  variant="secondary"
                  class="w-100 text-center"
                >
                  See My Points!
                </b-button>
              </b-col>
              <b-col
                cols="7"
                offset="5"
                offset-md="0"
                md="12"
                order="1"
                order-sm="1"
                order-md="2"
                class="p-0"
              >
                <blockquote class="text-white mt-5 bg-black p-4 mb-0">
                  <span class="text-blaze"
                    >"ScoreMaster has reverse engineered a way to raise your
                    score fast!"</span
                  ><br /><br />
                  <p>
                    <strong>Stu Burguiere</strong><br />
                    <small>Stu Does America - The Blaze</small>
                  </p>
                </blockquote>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <NumbersBluebar>
      <b-row align-h="center">
        <b-col cols="12">
          <p class="text-white text-center mt-2">
            <small
              >* Based upon an average of actual users of
              Scoremaster<sup>&reg;</sup>. Your results may vary and are not
              guaranteed.</small
            >
          </p>
        </b-col>
      </b-row>
    </NumbersBluebar>
  </div>
</template>

<script>
import NumbersBluebar from '@/components/common/NumbersBluebar'

export default {
  name: 'BlazeLanding',
  components: { NumbersBluebar },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.scoremaster.com/blaze/',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>

<style lang="scss" scoped>
.text-blaze {
  color: #fef900;
}

#hero .hero-text {
  margin-top: 50px;
}
</style>
