<template>
  <div class="special-offer">
    <svg
      id="SvgjsSvg1001"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <defs id="SvgjsDefs1002"></defs>
      <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          class="svg-inline--fa fa-certificate fa-w-16"
          data-icon="certificate"
          data-prefix="fas"
          viewBox="0 0 512 512"
          width="70"
          height="70"
        >
          <path
            :fill="color"
            d="M458.622 255.92l45.985-45.005c13.708-12.977 7.316-36.039-10.664-40.339l-62.65-15.99 17.661-62.015c4.991-17.838-11.829-34.663-29.661-29.671l-61.994 17.667-15.984-62.671C337.085.197 313.765-6.276 300.99 7.228L256 53.57 211.011 7.229c-12.63-13.351-36.047-7.234-40.325 10.668l-15.984 62.671-61.995-17.667C74.87 57.907 58.056 74.738 63.046 92.572l17.661 62.015-62.65 15.99C.069 174.878-6.31 197.944 7.392 210.915l45.985 45.005-45.985 45.004c-13.708 12.977-7.316 36.039 10.664 40.339l62.65 15.99-17.661 62.015c-4.991 17.838 11.829 34.663 29.661 29.671l61.994-17.667 15.984 62.671c4.439 18.575 27.696 24.018 40.325 10.668L256 458.61l44.989 46.001c12.5 13.488 35.987 7.486 40.325-10.668l15.984-62.671 61.994 17.667c17.836 4.994 34.651-11.837 29.661-29.671l-17.661-62.015 62.65-15.99c17.987-4.302 24.366-27.367 10.664-40.339l-45.984-45.004z"
            class="colorcurrentColor svgShape"
          ></path>
        </svg>
      </g>
    </svg>
    <p v-html="content"></p>
  </div>
</template>

<script>
export default {
  name: 'SpecialOfferBadge',
  props: {
    color: String,
    content: String,
  },
}
</script>

<style lang="scss" scoped>
.special-offer {
  color: white;
  height: 70px;
  width: 70px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
  }
}
</style>
