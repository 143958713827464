<template>
  <b-container fluid class="blue-bar py-4">
    <b-row>
      <b-col cols="12">
        <b-container>
          <b-row align-h="center">
            <b-col
              cols="12"
              md="6"
              lg="5"
              class="d-flex align-items-center mr-lg-3"
            >
              <p class="h1 font-weight-bolder text-white mb-0">+54</p>
              <p class="font-weight-semibold text-white mb-0">
                <span v-html="textLeft"></span>
              </p>
            </b-col>
            <b-col cols="12" md="6" lg="5" class="d-flex align-items-center">
              <p class="h1 font-weight-bolder text-white mb-0">4M</p>
              <p class="font-weight-semibold text-white mb-0">
                <span v-html="textRight"></span>
              </p>
            </b-col>
          </b-row>
          <slot />
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'NumbersBluebar',
  props: {
    textLeft: {
      type: String,
      default:
        'Average credit score increase using ScoreMaster<sup>&reg;</sup>',
    },
    textRight: {
      type: String,
      default: 'Number of customers serviced',
    },
  },
}
</script>
