<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/home_hero.jpg')"
      :mobileImage="require('@/assets/images/home_hero_mobile.jpg')"
      :mobileHeight="'100%'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="12"
            md="8"
            offset-md="4"
            lg="6"
            offset-lg="5"
            xl="5"
            offset-xl="6"
          >
            <div class="hero-text">
              <div class="bg-primary p-4">
                <h1 class="h1 font-weight-bolder text-white text-center">
                  The Fastest Way to Better Credit<sup>&trade;</sup>
                </h1>
              </div>
              <div class="p-4 text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <SpecialOfferBadge
                    color="#1F4DC5"
                    content="Special<br>Offer!"
                  />
                  <p class="ml-3 mb-0">
                    FREE for 14 Days. Then,<br />Only $14.99 per month!<br />
                    <a
                      target="_blank"
                      href="https://member.scoremaster.com/help/terms-and-privacy/risk-free-offer-details.htm"
                    >
                      <small class="text-primary">
                        Risk Free Offer Details »
                      </small>
                    </a>
                  </p>
                </div>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                >
                  Sign Up Now!
                </SignupLinkButton>
                <div class="my-3">
                  <small>Cancel Any Time</small>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <NumbersBluebar />
    <b-container class="py-3">
      <p class="h1 text-center my-5 font-weight-bolder">
        How Many Points <br class="d-md-none" />Do You Have?
      </p>
      <div class="mb-5 pt-3 d-grid">
        <img
          class="img-fluid mr-auto d-block"
          src="@/assets/images/home_hero_phone.jpg"
          alt="ScoreMaster®"
        />
        <div id="hero-text" class="pr-lg-0">
          <ul class="list-unstyled pt-2 pb-2">
            <li class="mb-3">
              Enroll in 1 minute.
            </li>
            <li class="mb-3">
              Get your points, fast.
            </li>
            <li class="mb-3">
              Get better deals!
            </li>
          </ul>
          <SignupLinkButton
            :to="{ name: 'IndividualSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4"
          >
            Take Me to Sign Up
          </SignupLinkButton>
        </div>
        <div class="hero-subtext">
          <p class="mt-4 pr-4 pr-md-0">
            Eliminate the guesswork with your credit score. Most Americans have
            plus points to add to their credit score, but no idea how to get
            them. ScoreMaster<sup>&reg;</sup> makes it easy.
          </p>
          <p class="pr-4">
            You'll also understand the credit score consequences if you spend
            too much or if you’re the victim of credit card theft.
          </p>
          <p class="pr-4">
            Credit score changes typically apply to all credit scores similarly
            including FICO<sup>&reg;</sup> &amp; VantageScore<sup>&reg;</sup>
            versions as credit scores are derived from
            TransUnion<sup>&reg;</sup>, Experian<sup>&reg;</sup> and Equifax<sup
              >&reg;</sup
            >
            where your credit utilization reported and used for scoring. *
          </p>
        </div>
      </div>
      <b-row align-h="center" class="pt-3 pt-lg-5 text-center">
        <b-col cols="12" md="8" lg="6">
          <p class="h1 font-weight-bolder">
            More Features to Protect Your Best Credit Score
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 text-center">
        <b-col cols="6" lg="2" class="mb-5">
          <IconSpendingImpact style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">Spending <br />Impact</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconScoreBuilder style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">
            Score<br />Builder<sup>&reg;</sup>
          </p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconMoneyManager style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">Money <br />Manager</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconInsurance style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">
            $1 Million Fraud <br />Insurance
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-4 pt-lg-6 text-center">
        <b-col cols="12">
          <p class="h1 font-weight-bolder">
            See What Our <br class="d-md-none" />Users Are Saying
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 text-center">
        <b-col cols="12" lg="4" class="d-flex">
          <div>
            <img
              src="@/assets/images/testimonial_1.png"
              height="85"
              alt="JB testimonial"
            />
          </div>
          <div class="ml-4 text-left" style="font-size: .9em;">
            <p class="text-primary mb-1">
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <small
                class="ml-3 align-center"
                style="height: 15px; line-height: 15px;"
                >Verified Purchase</small
              >
            </p>
            <p>
              The accurate information helps focus &amp; target specific needs
              to get your credit score up quickly.
            </p>
            <p class="text-gray-400">- J.B. in Irvine, CA</p>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="d-flex">
          <div>
            <img
              src="@/assets/images/testimonial_2.png"
              height="85"
              alt="JB testimonial"
            />
          </div>
          <div class="ml-4 text-left" style="font-size: .9em;">
            <p class="text-primary mb-1">
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <small
                class="ml-3 align-center"
                style="height: 15px; line-height: 15px;"
                >Verified Purchase</small
              >
            </p>
            <p>
              ScoreMaster<sup>&reg;</sup> is the best! It's helped so much, lots
              of control, ease of use!
            </p>
            <p class="text-gray-400">- L.C. in Boston, MA</p>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="d-flex">
          <div>
            <img
              src="@/assets/images/testimonial_3.png"
              height="85"
              alt="JB testimonial"
            />
          </div>
          <div class="ml-4 text-left" style="font-size: .9em;">
            <p class="text-primary mb-1">
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <IconStar class="icon icon-primary" />
              <small
                class="ml-3 align-center"
                style="height: 15px; line-height: 15px;"
                >Verified Purchase</small
              >
            </p>
            <p>
              ScoreMaster<sup>&reg;</sup> is an amazing tool! It tells you
              exactly what you need to do to raise your score.
            </p>
            <p class="text-gray-400">- S.R. in Erie, PA</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="py-3">
      <FunFastSimpleSlider
        id="how-it-works"
        class="mb-6 mb-lg-7 pb-lg-7"
        ctaText="Take Me to Sign Up"
      />
      <div id="benefits" class="alternating-blocks fullwidth">
        <AlternatingBlock
          :image="require('@/assets/images/borrower_benefit_uniqueplan.jpg')"
          :mobileImage="
            require('@/assets/images/borrower_benefit_uniqueplan_mobile.jpg')
          "
          title="credit score monitoring tool for borrower"
          titleMobile="credit score monitoring in mobile for borrower"
        >
          <template slot="title">
            Your Unique Plan
          </template>
          <p class="h5 font-weight-bold">
            A Plan for You, Not The Averages
          </p>
          <p>
            ScoreMaster<sup>&reg;</sup> lets you save a precise Plan that is
            unique for your credit situation showing you how much to pay and to
            which account. Your Post Payment By dates are not your due dates but
            are uniquely timed by ScoreMaster<sup>&reg;</sup> to be prior your
            creditor’s reporting cycle.
          </p>
          <p class="h5 font-weight-bold">
            Simple, Easy &amp; Fun
          </p>
          <p>
            Slide the dial with one finger to easily see your available credit
            score points and how to get them. You can also see how to avoid
            lowering your credit score as you spend.
          </p>
        </AlternatingBlock>
        <AlternatingBlock
          :image="require('@/assets/images/borrower_benefit_scoreandmore.jpg')"
          :mobileImage="
            require('@/assets/images/borrower_benefit_scoreandmore_mobile.jpg')
          "
          alignment="right"
          title="money manager and credit score tool for borrower"
          titleMobile="money manager and credit monitoring for borrower"
        >
          <template slot="title">
            A Full Suite of Features
          </template>
          <p class="h5 font-weight-bold">
            Your Money, Credit &amp; Identity in One Place
          </p>
          <p>
            Your membership includes ScoreMaster<sup>&reg;</sup>,
            ScoreBuilder<sup>&reg;</sup>, ScoreTracker, Money Manager, Smart
            Credit Report<sup>&reg;</sup>, 3B Report &amp; Scores and $1 Million
            in Fraud Protection for your whole family and more!
          </p>
          <p>
            Use the Action buttons to ask questions or solve problems on your
            credit report or online banking transactions.
          </p>
          <SignupLinkButton
            :to="{ name: 'IndividualSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4"
          >
            Sign Up Now!
          </SignupLinkButton>
        </AlternatingBlock>
      </div>
    </b-container>
    <AlternatingFeatures id="features" />
  </div>
</template>

<script>
import NumbersBluebar from '@/components/common/NumbersBluebar'
import IconSpendingImpact from '@/assets/svg/icon_cart.svg'
import IconScoreBuilder from '@/assets/svg/icon_SB.svg'
import IconMoneyManager from '@/assets/svg/icon_MM.svg'
import IconInsurance from '@/assets/svg/icon_FI.svg'
import IconStar from '@/assets/svg/icon_star.svg'
import FunFastSimpleSlider from '@/components/common/FunFastSimpleSlider'
import AlternatingBlock from '@/components/common/AlternatingBlock'
import AlternatingFeatures from '@/components/common/AlternatingFeatures'
import SpecialOfferBadge from '@/components/common/SpecialOfferBadge'

export default {
  name: 'LandingPage',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    NumbersBluebar,
    IconSpendingImpact,
    IconScoreBuilder,
    IconMoneyManager,
    IconInsurance,
    IconStar,
    FunFastSimpleSlider,
    AlternatingBlock,
    AlternatingFeatures,
    SpecialOfferBadge,
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>

<style lang="scss" scoped>
.d-grid {
  display: flex;
  flex-wrap: wrap;

  img {
    width: 40%;
    height: 40%;
    padding-right: 1rem;
  }
  #hero-text {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .d-grid {
    display: grid;
    grid-template-columns: 40% 60%;
    max-width: 600px;
    margin: 0 auto;
    column-gap: 2rem;
    width: auto;

    img {
      grid-row-start: 1;
      grid-row-end: span 2;
      width: auto;
      height: auto;
      padding-right: 0;
    }
    #hero-text {
      width: auto;
    }

    .hero-subtext {
      grid-column-start: 2;
    }
  }
}
@media (min-width: 991px) {
  .d-grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
