<template>
  <div class="d-flex">
    <b-img
      :src="require(`@/assets/images/${imgFile}`)"
      :height="parseInt(imgHeight)"
      :alt="imgAlt"
    />
    <div class="ml-4 text-left" style="font-size: .9em;">
      <p class="text-primary mb-1">
        <span v-for="index in parseInt(starRating)" :key="index">
          <IconStar class="icon icon-primary" />
        </span>
        <small
          class="ml-3 align-center"
          style="height: 15px; line-height: 15px;"
          >Verified Purchase</small
        >
      </p>
      <p v-html="testimony" />
      <p v-if="author" class="text-gray-400">- {{ author }}</p>
    </div>
  </div>
</template>

<script>
import IconStar from '@/assets/svg/icon_star.svg'

export default {
  name: 'Testimonial',
  props: {
    imgFile: String,
    imgAlt: String,
    author: String,
    testimony: String,
    imgHeight: {
      type: Number,
      default: 85,
    },
    starRating: {
      type: Number,
      default: 5,
    },
  },
  components: {
    IconStar,
  },
}
</script>

<style lang="scss" scoped></style>
